import { MultiStepRoute } from "alicia-design-system/dist/esm/types";
import { BackgroundProps } from "features/shared/layout/plugins/background";
import { UseQueryParamsReturnType } from "hooks/useQueryParams";
import { AvailableLanguageType, BrandType } from "src/types";
import { TFunction } from "i18next";
import { FileState } from "alicia-design-system";
import { ComponentWithAs, IconProps } from "@chakra-ui/react";

export type MainQueryParams = UseQueryParamsReturnType & PartnerQueryType;

export type CheckinQueryParams = UseQueryParamsReturnType & {
  AVB_YEAR?: string;
  AVB_EVENT?: string;
  BAV_YEAR?: string;
  BAV_EVENT?: string;
  type?: CheckinTypeEnum;
  lang?: string;
};

export enum CheckinTypeEnum {
  SUPPLIER = "supplier",
  FREELANCER = "freelancer",
}

export type PartnerColors = {
  id: string;
  key: string;
  value: string;
};

export type PartnerCurrency = {
  id: string;
  currency_name: string;
  code: string;
};

export type PartnerDetailsType = {
  brand?: BrandType;
  colors?: PartnerColors[]; //https://aliciainsurance.atlassian.net/wiki/spaces/TECH/pages/295567361/Alicia+2.0+Colors
  languages: any;
  translations: any;
  default_language: AvailableLanguageType | null;
  available_languages: AvailableLanguageType[] | [];
  gtm: string;
  gtmId: string;
  hideKvk: boolean;
  allow_manual_company_information_input: boolean;
  redirectCalculator: boolean;
  name: string;
  manualCompanyInformationEnabled: boolean;
  validatePolicyStartDate: boolean;
  loaded: boolean;
  getPartner: () => Promise<void>;
  clearPartner: () => void;
  support_email: string;
  support_phone_number: string;
  trengo_key: string;
  is_direct: boolean;
  expo_ip?: string;
  checkinReturnURL?: string;
  setExpoIp: (expo_ip: string) => void;
  setCheckInReturnUrl: (returnUrl: string) => void;
  enable_visual_effects: boolean;
  hide_professions_on_welcome_screen: boolean;
  hide_profession_page: boolean;
  currency?: PartnerCurrency;
  updatePartnerColors: (colors: PartnerColors[]) => void;
};

/**
 * Auth types
 */

export type AuthDetailsType = {
  user?: UserType;
  authenticated: boolean;
  access_token?: string;
  external_id?: string;
  refresh_token?: string;
  signIn: (email: string) => Promise<void>;
  getUser: () => Promise<UserType>;
  setUserToken: (data: PartnerQueryType) => Promise<void>;
  refreshUserToken: () => Promise<void>;
  adminAuthenticate: (refreshToken: string) => Promise<void>;
  refreshAdminToken: () => Promise<void>;
  clearAuth: () => void;
  clearAdminAuth: () => void;
  putUserProfession: (data: ProfessionsResultType) => Promise<void>;
  manageUserAddress: (
    data: AddressType,
    type: UserAddressTypeEnum
  ) => Promise<void>;
  putUserCoc: (data: PutUserCocType) => Promise<void>;
  putUserPayment: (data: PutUserPaymentConcentType) => Promise<void>;
  putUserBirthDate: (data: PutUserDOBType) => Promise<void>;
  insuranceFlow: InsuranceFlow;
  setInsuranceFlow: (insuranceFlow: InsuranceFlow) => void;
  admin?: AdminAuthDetailsType;
};

export type AdminAuthDetailsType = {
  access_token?: string;
  refresh_token?: string;
  name?: string;
  returnURL?: string;
};

export type PartnerQueryType = {
  external_id?: string;
  signature?: string;
  _gl?: string;
  signature_created_at?: string;
  profession_id?: string;
  profession_name?: string;
  insurance_calculator?: string;
  expo_ip?: string;
};

export type AuthResponseType = {
  access: string;
  refresh?: string;
};
export type BMIHistory = {
  bmi: number;
  weight: number;
  height: string;
};

/**
 * User types
 */

export type UserType = {
  company_name?: string;
  bmi_history?: BMIHistory[];
  chamber_of_commerce_number: string;
  chamber_of_commerce_number_verified: boolean;
  company_address?: AddressType;
  personal_address?: AddressType;
  date_of_birth?: string;
  email: string;
  entry_date?: string;
  first_name: string;
  id: string;
  last_name: string;
  phone_number?: string;
  policy_number?: string;
  profession?: string | ProfessionType;
  consent_share_data_with_partner: boolean;
  flagged: boolean;
  aov_date_of_birth?: boolean;
  iban?: string;
  iban_last4?: string;
};

export type AddressType = {
  id?: string;
  city: string;
  country?: string;
  house_number: string;
  house_number_addition?: string;
  postal_code?: string;
  street: string;
  type?: UserAddressTypeEnum;
};

export enum UserAddressTypeEnum {
  COMPANY = "company",
  PERSONAL = "personal",
}

export type ProfessionType = {
  id: string;
  name: string;
};

export type ProfessionsResultType = {
  value: string;
  label?: string;
};

export type KvkResultType = {
  value: string;
  label: string;
  extra?: AddressType;
};

export type PutProfessionType = {
  profession: string;
};

export type PutCustomProfessionType = {
  first_name: string;
  email: string;
  custom_profession_description: string;
};

export type putFormalitiesType = {
  formalities_passed: boolean;
  formalities_failed_reason_description?: string;
};

export type PutUserCocType = {
  company_name: string;
  chamber_of_commerce_number: string;
  chamber_of_commerce_number_verified: boolean;
};

export type PutUserPaymentConcentType = {
  consent_share_data_with_partner: boolean;
  iban: string;
};

export type PutUserDOBType = {
  aov_date_of_birth: boolean;
  date_of_birth: string;
};

/**
 * cart types
 */

export type CartItemInsuranceProductType = {
  id: string;
  name: string;
  description: string;
  price?: string;
  net_price?: string;
  require_identity_verification?: boolean;
  type?: string | null;
  price_currency?: string;
  require_kvk?: boolean;
};

export type CartItemExtraCoverageType = {
  id: string;
  question: CartItemExtraCoverageQuestionType;
  option: CartItemExtraCoverageOptionType;
};

type CartItemExtraCoverageQuestionType = {
  id: string;
  text: string;
};

type CartItemExtraCoverageOptionType = {
  value: string;
  percentage_price_increase: string;
  amount_price_increase: string;
};

export type CartItemType = {
  id: string;
  insurance_product: CartItemInsuranceProductType;
  list_items: CartItemListItemType[];
  order_date: string;
  order_id: string;
};

export type CartItemListItemType = {
  question_id: string;
  label: string;
  value: string;
  group: CartItemListItemGroupEnum;
  type: CartItemListItemTypeEnum;
};

export enum CartItemListItemTypeEnum {
  TEXT = "text",
  DATE = "date",
  MONEY = "money",
}

export enum CartItemListItemGroupEnum {
  EXTRA_COVERAGE = "extra_coverage",
  PRICE_INCREASE = "price_increase",
  TBP_PRICE = "tbp_price",
  CONSUMER_ELECTRONICS = "consumer_electronics",
}

export type PriceAnswerType = {
  id: string;
  date_value?: string | null;
  float_value?: string | null;
  money_value?: string | null;
  option?: PriceAnswerOptionType;
  question: PriceAnswerQuestionType;
};

export type PriceAnswerOptionType = {
  float_value?: string | number | null;
  id: string;
  int_value?: number;
  value?: string;
};

export type PriceAnswerQuestionType = {
  card_label?: string | null;
  group?: string;
  id: string;
  text: string;
};

export type CartType = {
  cartItemList: CartItemType[];
  cartItemAOV?: CartItemType[];
  entryDate?: string;
  getCartItemList: () => Promise<void>;
  setAOVCartItem: (cartItem: CartItemType[]) => void;
  clearCartItems: () => Promise<void>;
  setEntryDate: (entryDate: string) => void;
};

export type TranslationLanguageType = {
  language: string;
  setLanguage: (data: string) => void;
};

/**
 * product types
 */
export type ProductType = {
  description?: string;
  id: string;
  invoice_strategy?: string;
  is_price_increase?: string;
  card_label?: string;
  name?: string;
  net_price?: string;
  ordinal?: number;
  policy_terms_document?: string;
  ipid_document?: string;
  comparison_chart?: string;
  old_ipid_document?: string;
  pre_purchase_description?: string;
  price?: number;
  price_currency?: string;
  step_out_url?: string;
  user_step_out_url?: string;
  checked?: boolean;
  is_eligible?: boolean;
  type?: string | null;
  active_policies: PolicyType[];
  is_funnel_available: boolean;
  drop_out_status: DropoutEnum.HARD_DROP_OUT | DropoutEnum.DROP_OUT | null;
  claim_type?: ClaimType;
  require_kvk?: boolean;
  require_profession?: boolean;
};

export enum DropoutEnum {
  HARD_DROP_OUT = "hard_drop_out",
  DROP_OUT = "drop_out",
}

export type ClaimType = {
  id: string;
  form: string;
  claim_type: string;
};

export type ProductListType = {
  products: ProductType[];
  isProductsAvailable: boolean;
  setProducts: (data: ProductType[]) => void;
  clearProducts: () => void;
  setIsPoliciesAvailable: (value: boolean) => void;
  isPoliciesAvailable?: boolean;
  setIsPastPoliciesAvailable: (value: boolean) => void;
  isPastPoliciesAvailable?: boolean;
};

export type ProductPDP = {
  title: string;
  details: ProductPDPList[];
};

export type ProductPDPList = {
  id: string;
  question: string;
  answer: string;
  icon: string;
};
type PreviousDocuments = {
  created_date: string;
  url: string;
};

/**
 * policy types
 */
export type PolicyType = {
  id: string;
  premium: string;
  premium_untaxed: string;
  start: string;
  end: string;
  product: ProductType;
  active: boolean;
  risk_class: {
    id: string;
    name: string;
    product: string;
  };
  previous_policy_document_versions?: PreviousDocuments[];
  policy_number: string;
  confirmed_terms_of_conditions_at: string;
  policy_document: string;
  note: string;
};

export type PoliciesType = {
  policies: PolicyType[];
  setPolicies: (data: PolicyType[]) => void;
  clearPolicies: () => void;
};

export type PoliciesCreationType = {
  id: string;
  start: string;
  product: string;
  price?: string;
};

export type PoliciesCreationReasonType = {
  consequence: string;
  id: string;
  question_id: string;
  message?: string;
};

export type PoliciesCreationResponseType = {
  data: PoliciesCreationType;
  details: {
    non_field_errors: string[];
    reasons: PoliciesCreationReasonType[];
  };
  status: string;
};

// companies types
export type CocAddressType = {
  city: string;
  country?: string;
  house_number?: string;
  house_number_addition?: string;
  postal_code?: string;
  street: string;
};

export type CocListResponseType = {
  id: string;
  name: string;
  address: CocAddressType;
};

export type CompanyType = {
  label: string;
  value: string;
  extra?: CocAddressType;
};

export type SearchProfessionType = {
  label: string;
  value: string;
};

// Form Inputs
export type IFormInput = {
  [id: string]: string | number;
};

export type QuestionType = {
  id: string;
  group:
    | "normal"
    | "price"
    | "quiz"
    | "bmi"
    | "extra_coverage"
    | "medical"
    | "checkin_flow"
    | "multiselect";
  ordinal?: string;
  placeholder?: string;
  product: string;
  product_name?: string;
  price_currency?: string;
  subtext?: string;
  system_name?: string;
  text?: string;
  options: QuestionOptionType[];
  user_answer?: UserAnswerType[];
  card_label?: string;
  label?: string;
  additional_info?: string;
};
export type TextQuestionType = {
  id: string;
  group:
    | "normal"
    | "price"
    | "quiz"
    | "bmi"
    | "extra_coverage"
    | "medical"
    | "checkin_flow"
    | "multiselect";
  ordinal?: string;
  placeholder?: string;
  product: string;
  product_name?: string;
  price_currency?: string;
  subtext?: string;
  system_name?: string;
  text?: string;
  user_answer?: UserAnswerType[];
  card_label?: string;
};

export type UserAnswerType = {
  id: string;
  option: string;
  additional_detail?: userAnswerAdditionalDetails;
  date_value: string | null;
  float_value: string | number | null;
  money_value: string | null;
  multi_select_options: string[] | null;
};

export type userAnswerAdditionalDetails = {
  id: string;
  reason: string;
};

export type ProductQuestionType = {
  questions?: QuestionType[];
};

export type ExtraCoverageType = {
  [product: string]: QuestionType[];
};

export type QuestionOptionType = {
  consequence?: string;
  default?: boolean;
  id: string;
  ordinal?: string;
  value: string;
  label?: string;
  allow_additional_details?: boolean;
  additional_detail_title?: string;
  additional_detail_description?: string;
  amount_price_increase?: string;
  multi_selectable?: boolean;
  int_value?: number;
  dependency_options?: {
    [id: string]: {
      percentage_price_increase: number;
      amount_price_increase: number;
    };
  };
};

export type FormattedQuestionsType = {
  questions?: QuestionType[];
  sortedRoutes?: SortedRoutesType[];
  extraCoverages?: ExtraCoverageType;
  currentProduct?: string;
  currentQuestionIndex: number;
  latestQuestionIndex: number;
  nextRoute: string;
  animate: string; //animation for custom layouts
  hidePrevious: boolean;
  setAnimate: (value: any) => void;
  setHidePrevious: (value: boolean) => void;
  getQuestions: (
    productIdList: string[],
    isCalculator?: boolean
  ) => Promise<void>;
  setNextRoute: (route: string) => void;
  setSortedRoutes: (routes: SortedRoutesType[]) => void;
  setCurrentQuestionIndex: (index: number, latestindex: number) => void;
  clearQuestions: () => void;
  formalities: string[];
  formalitiesChecked: (item: string) => void;
  setAovPresetPrices: (productId: string) => Promise<void>;
  presets?: AOVPresetPriceType;
};

export type AOVPresetPriceType = {
  [title: string]: {
    net_price: string;
    gross_price: string;
  };
};

export type AovPresetCardProps = {
  keyName: string;
  title: string;
  type: string;
  mostChosen: boolean;
  description: string;
  choices: {
    title: string;
    label: string;
    group: string;
    system_name: string;
    question: string;
    option: string;
  }[];
  price: {
    net_price: string;
    gross_price: string;
  };
  icon: ComponentWithAs<"svg", IconProps>;
  priceLoading: boolean;
};

export type SortedRoutesType = Omit<MultiStepRoutesType, "component">;
export type MultiStepRoutesType = {
  name: string;
  path: string;
  component: React.FC;
  type?: MultiStepRouteType;
  customTitle?: string;
  product?: string;
  isSkipTitle?: boolean;
  system_name?: string;
  group?: string;
  question?: string;
  questions?: QuestionType[];
};

export enum MultiStepRouteType {
  PERSONAL = 0,
  ADDITIONAL = 1,
  PAYMENT = 2,
  CLAIM = 3,
  EXIT_SURVEY = 4,
  INTRO = 5,
  CUSTOMER_EFFORT_SCORE = 6,
}

export type FileUploadedType = {
  id: string;
  file: string;
  name: string;
};

// Answer types
export type AnswersType = {
  answers: AnswerType[];
  setAnswer: (
    questionId: string,
    optionId: string,
    isCheckinFlow?: boolean
  ) => Promise<void>;
  setAnswerForIncomeOrBirthDate: (
    questionId: string,
    value: string,
    type: "birthDate" | "income" | "suggestion" | "price"
  ) => Promise<void>;
  setAnswerForBMI: (questionId: string, height: number, weight: number) => void;
  setAnswerForFloat: (
    questionId: string,
    value: string,
    type?: "freelancer" | "supplier"
  ) => Promise<void>;
  setAnswerForText: (questionId: string, value: string) => Promise<void>;
  setAnswerForDevice: (questionId: string, deviceName: string) => Promise<void>;
  setAnswerForDocumentUpload: (
    questionId: string,
    files: FileState[]
  ) => Promise<void>;
  setAnswerForMultiSelect: (
    questionId: string,
    value: string[]
  ) => Promise<void>;
  clearAnswers: () => void;
};

export type AnswerType = {
  question: string;
  id?: string;
  option?: string | BMIRequestType;
  date_value?: string;
  money_value?: string;
  float_value?: number;
  text_value?: string;
  files?: FileState[] | string[];
  multi_select_options?: string[];
};

export type FileReturnType = {
  id: string;
  file: string;
  name: string;
};

export type PreviousAnswerResponseType = {
  id: string;
  date_value?: string;
  money_value?: string;
  float_value?: number;
  text_value?: string;
  files?: FileReturnType[];
  additional_detail?: userAnswerAdditionalDetails;
  option?: QuestionOptionType | string;
  override?: string;
  question: string;
};

export enum StepTypeEnum {
  DEFAULT = "default",
  CALCULATOR = "calculator",
  AOV = "aov",
}

export type StepsType = {
  steps: HeaderStepType[];
  currentId: string;
  isPageLoading?: boolean;
  setSteps: (data: StepTypeEnum, t: TFunction) => void;
  setCurrentStepId: (id: string) => void;
  setIsPageLoading: (value: boolean) => void;
};

export type HeaderStepType = {
  id: string;
  title: string;
  widthPercentage: string;
  mobileWidthPercentage: string;
};

// User choices types
export type UserChoicesType = {
  userChoices: UserChoiceType[];
  setUserChoice: (data: UserChoiceType) => void;
  clearChoices: () => void;
};

export type UserChoiceType = {
  systemName: string;
  choice?: number | string | string[];
  customData?: string;
  questionId?: string;
};

// BMI types
export type BMIRequestType = {
  height: number;
  weight: number;
  product?: string;
};

export type BMIResponseType = {
  answer: { id: string; consequence: string };
  bmi: number;
  created: string;
  height: string;
  id: string;
  user: string;
  weight: number;
  failed: boolean;
};

//refund types
export type RefundType = {
  amount: number;
  currency: string;
};

//cancel insurance survey type
export type CancelInsuranceSurveyType = {
  policyId: string;
  routes: MultiStepRoute[];
  currentRouteIndex: number;
  nextRoute: string;
  latestRouteIndex: number;
  setRoutes: (data: any) => void;
  setCurrentRouteIndex: (index: number, latestRouteIndex: number) => void;
  setNextRoute: (route: string) => void;
  setPolicyId: (policyId: string) => void;
};

export type SurveyAnswersType = {
  question_id: number;
  choice: string;
  comment: null | string;
  question?: {
    id?: number;
  };
};

export type SimpleSatDataType = {
  answers: any[];
  ticket: SimpleSatTicketType;
};

export type SimpleSatTicketType = {
  id?: number;
  external_id?: string;
  subject?: string;
};

export type SimpleSatStateType = {
  data: SimpleSatDataType;
  loading: boolean;
  createResponse: (
    surveyId: number,
    questionId: number,
    data: any,
    customData?: { fieldType: string; fieldValue: string }
  ) => Promise<boolean>;
  clear: () => void;
};

// survey props types
export type SurveyPropsType = {
  surveyId: number;
  surveyType?: string;
  typeId?: string;
  surveyQuestionId: number;
  previousQuestionId?: number;
  onSkip?: () => void;
  onNext: () => void;
  title?: string;
  titleDescription?: string;
  subText?: string;
  subTextDescription?: string;
  inputTitle?: string;
  inputPlaceholder?: string;
  isPreviousChoiceShown?: boolean;
};

export type SurveyRoutesType = {
  name: string;
  path: string;
  component: React.FC<any>;
  surveyId: number | string;
  surveyQuestionId?: number | string;
  previousQuestionId?: number;
  navigateRoute?: string;
  title?: string;
  titleDescription?: string;
  subText?: string;
  subTextDescription?: string;
  inputTitle?: string;
  inputPlaceholder?: string;
  isPreviousChoiceShown?: boolean;
};

export type SurveyType = {
  currentRouteIndex: number;
  latestRouteIndex: number;
  setCurrentRouteIndex: (value: number, latestRouteIndex: number) => void;
  clearSurvey: () => void;
};

// support types
export enum SupportActionEnum {
  CALL = "call",
  MAIL = "mail",
  CHAT = "chat",
  FAQ = "faq",
}

export type TitleType = {
  title: string;
  setTitle: (title: string) => void;
};

/**
 * Contact request types
 */

export type ContactRequestType = {
  first_name: string;
  last_name?: string;
  phone_number: string;
  email: string;
  policy_number?: string;
  intent: string;
};

/**
 * Product group types
 */
export enum ProductGroup {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

// error page props types
export type ErrorPagePropsType = {
  title?: string;
  description?: string;
  buttonText?: string;
  backdrop?: BackgroundProps;
  navigateTo?: string;
};

export type CheckInType = {
  avbEvent?: string;
  bavEvent?: string;
  avbYear?: string;
  bavYear?: string;
  checkInType?: CheckinTypeEnum;
  checkInContent?: {
    bavTitle?: string;
    avbTitle?: string;
  };
  setCheckInData: (data: {
    avbEvent?: string;
    bavEvent?: string;
    avbYear?: string;
    bavYear?: string;
    checkInType?: string;
  }) => void;
  setCheckInContent: (data: { bavTitle?: string; avbTitle?: string }) => void;
  clearCheckInData: () => Promise<void>;
};

export enum InsuranceFlow {
  DEFAULT = "default",
  CALCULATOR = "calculator",
  CHECKIN = "checkin",
}

export type NavigationRouteType = {
  id: string;
  label: string;
  route?: string;
  icon?: ComponentWithAs<"svg", IconProps>;
  subMenu?: NavigationSubRouteType[];
  count?: number;
};

export type NavigationSubRouteType = {
  id: string;
  label: string;
  route?: string;
  icon?: ComponentWithAs<"svg", IconProps>;
};

//server-error-codes
// Should add the rest of error types
export type ErrorType = "login" | "other" | "email-exists";

export type CustomErrorCodeResponseType = {
  code: number;
  message: string;
};

export type DeviceType = {
  label: string;
  value: string;
  extra?: DeviceDetailsType;
};

export type DeviceDetailsType = {
  manufacturer: string;
  price?: string;
};

export enum QuestionMultiSelectTypeEnum {
  ALL = "all",
  NONE = "none",
  NOT_SELECTABLE = "not-selectable",
}

export type RoutesType = {
  name: string;
  path: string;
  component: React.FC<any>;
  type: MultiStepRouteType;
  customTitle: string;
  isSkipTitle?: boolean;
  system_name?: string;
  product?: string;
  group?: string;
  question?: string;
  questions?: QuestionType[];
};

export type OptionListType = {
  value: string;
  label: string;
};

// invoice types
export type InvoiceType = {
  document: string;
  id: string;
  invoice_date: string;
  invoice_number: string;
  status: string;
  total: string;
};

export type SelectValueType = {
  label: string;
  value: string;
  infoText?: string;
  isDisabled?: boolean;
  disableInfoText?: string;
};
