import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  AnswerType,
  AnswersType,
  FileReturnType,
  PreviousAnswerResponseType,
} from "../types/types";
import { ANSWERS_STORE } from "../constants/constant";
import {
  getPreviousAnswers,
  postDocuments,
  upsertAnswer,
} from "services/api/answers";
import { useCartStore } from "./cart";
import { useAuthStore } from "./authentication";
import { FileState } from "alicia-design-system";

const initialAnswersState = {
  answers: [],
};

export const useAnswersStore = create(
  devtools(
    persist<AnswersType>(
      (set) => ({
        ...initialAnswersState,
        setAnswer: async (
          questionId: string,
          optionId: string,
          isCheckinFlow?: boolean
        ) => {
          try {
            const previousAnswers: PreviousAnswerResponseType[] =
              await getPreviousAnswers(questionId);
            const upsertedAnswer: AnswerType = await upsertAnswer({
              question: questionId,
              id: previousAnswers.length ? previousAnswers[0].id : undefined,
              option: optionId,
            });
            upsertedAnswer["question"] = questionId;
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [
                  ...updatedAnswers,
                  {
                    id: upsertedAnswer.id,
                    question: questionId,
                    option: optionId,
                  },
                ],
              };
            });

            if (!isCheckinFlow) {
              const { getCartItemList } = useCartStore.getState();
              await getCartItemList();
            }
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setAnswerForIncomeOrBirthDate: async (
          questionId: string,
          value: string,
          type: "birthDate" | "income" | "suggestion" | "price"
        ) => {
          try {
            const previousAnswers: PreviousAnswerResponseType[] =
              await getPreviousAnswers(questionId);
            const answer: AnswerType = {
              question: questionId,
              id: previousAnswers.length ? previousAnswers[0].id : undefined,
            };
            if (type === "birthDate") {
              answer.date_value = value;
            }
            if (type === "income" || type === "price") {
              answer.money_value = value.replace(/\D/g, "");
            }
            const upsertedAnswer: AnswerType = await upsertAnswer(answer);
            upsertedAnswer["question"] = questionId;
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [...updatedAnswers, upsertedAnswer],
              };
            });
            if (type === "birthDate") {
              const { putUserBirthDate } = useAuthStore.getState();
              putUserBirthDate({
                aov_date_of_birth: true,
                date_of_birth: value,
              });
            }
            const { getCartItemList } = useCartStore.getState();
            await getCartItemList();
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setAnswerForDevice: async (questionId: string, deviceName: string) => {
          try {
            const previousAnswers: PreviousAnswerResponseType[] =
              await getPreviousAnswers(questionId);
            const answer: AnswerType = {
              question: questionId,
              id: previousAnswers.length ? previousAnswers[0].id : undefined,
            };
            answer.text_value = deviceName;
            const upsertedAnswer: AnswerType = await upsertAnswer(answer);
            upsertedAnswer["question"] = questionId;
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [...updatedAnswers, upsertedAnswer],
              };
            });
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setAnswerForFloat: async (
          questionId: string,
          value: string,
          type?: "freelancer" | "supplier"
        ) => {
          try {
            const previousAnswers: PreviousAnswerResponseType[] =
              await getPreviousAnswers(questionId);
            const answer: AnswerType = {
              question: questionId,
              id: previousAnswers.length ? previousAnswers[0].id : undefined,
            };
            answer.money_value = value;
            const upsertedAnswer: AnswerType = await upsertAnswer(answer, type);
            upsertedAnswer["question"] = questionId;
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [...updatedAnswers, upsertedAnswer],
              };
            });
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setAnswerForText: async (questionId: string, value: string) => {
          try {
            const previousAnswers: PreviousAnswerResponseType[] =
              await getPreviousAnswers(questionId);
            const answer: AnswerType = {
              question: questionId,
              id: previousAnswers.length ? previousAnswers[0].id : undefined,
            };
            answer.text_value = value;
            const upsertedAnswer: AnswerType = await upsertAnswer(answer);
            upsertedAnswer["question"] = questionId;
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [...updatedAnswers, upsertedAnswer],
              };
            });
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setAnswerForBMI: (
          questionId: string,
          height: number,
          weight: number
        ) => {
          try {
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [
                  ...updatedAnswers,
                  {
                    id: questionId,
                    question: questionId,
                    option: { height: height, weight: weight },
                  },
                ],
              };
            });
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setAnswerForDocumentUpload: async (
          questionId: string,
          files: FileState[]
        ) => {
          try {
            const previousAnswers: PreviousAnswerResponseType[] =
              await getPreviousAnswers(questionId);
            const { user } = useAuthStore.getState();
            const documents = await postDocuments(files, user?.id);
            const answer: AnswerType = {
              id: previousAnswers.length ? previousAnswers[0].id : undefined,
              question: questionId,
              files: documents.map((doc: FileReturnType) => doc.id),
            };
            await upsertAnswer(answer);
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [
                  ...updatedAnswers,
                  {
                    id: questionId,
                    question: questionId,
                    files,
                  },
                ],
              };
            });
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setAnswerForMultiSelect: async (
          questionId: string,
          multi_select_options: string[]
        ) => {
          try {
            const previousAnswers: PreviousAnswerResponseType[] =
              await getPreviousAnswers(questionId);
            const answer: AnswerType = {
              id: previousAnswers.length ? previousAnswers[0].id : undefined,
              question: questionId,
              multi_select_options,
            };
            const upsertedAnswer: AnswerType = await upsertAnswer(answer);
            upsertedAnswer["question"] = questionId;
            set((state) => {
              const updatedAnswers = state.answers.filter(
                (answer) => answer.question !== questionId
              );

              return {
                answers: [...updatedAnswers, upsertedAnswer],
              };
            });
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        clearAnswers: () => {
          set(() => initialAnswersState);
        },
      }),
      {
        name: ANSWERS_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
